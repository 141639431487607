<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <svgicon v-if="headerSvg" :name="headerSvg" width="22px" height="22px"></svgicon>
        <CIcon v-if="headerIcon" :name="headerIcon"/>
        <strong>{{caption}}</strong>
        <span v-if="count > 0"> ({{count}}) </span>
        <CDropdown
          v-if="addBtn && !is_all_checked"
          size="sm"
          :toggler-text="addBtnText"
          color="primary"
          class="m-0 d-inline-block float-right"
        >
          <CDropdownItem @click="onImportExample" v-if="importBtn">{{ $t('cloud.title.import_example_download') }}</CDropdownItem>
          <CDropdownItem @click="onImport" v-if="importBtn">{{caption}} {{ $t('cloud.title.import') }}</CDropdownItem>
          <CDropdownItem @click="onAdd">{{caption}} {{ $t('button.add') }}</CDropdownItem>
        </CDropdown>

        <CButton
          v-if="is_all_checked"
          size="sm"
          color="danger"
          @click="deleteCheckedItems"
          class="m-0 d-inline-block float-right">
          {{ $t('button.delete') }}
        </CButton>
      </slot>
    </CCardHeader>

    <CCardBody>
      <CRow v-if="checkable" class="checkable">
        <div class="multiBox">
          <CInputCheckbox v-show="!is_all_checked" id="all_checkbox" class="all_checkbox" :checked.sync="is_all_checked" @update:checked="updateStateCurrentPageAllItems" />
          <div v-show="is_all_checked" class="checkbox_label">
            <div v-if="isAllCheckboxChecked()" class="checkbox_icon_wrap"> <!-- 전체 선택 -->
              <i class="el-icon-check checkbox_icon" @click="onClickCheckbox"></i>
            </div>
            <div v-else class="checkbox_icon_wrap">  <!-- 부분 선택 -->
              <i class="el-icon-minus checkbox_icon" @click="onClickCheckbox"></i>
            </div>
          </div>
        </div>
      </CRow>
    
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="c_items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        :clickableRows="clickableRows"
        @row-clicked="onRowClick"
      >
        <td slot="avatar" class="text-center align-middle" slot-scope="{item}">
          <div class="c-avatar">
            <img :src="getUserImageSrc(item.avatar.url)" class="c-avatar-img" alt="">
            <span
              class="c-avatar-status"
              :class="`bg-${item.avatar.status || 'secondary'}`"
            ></span>
          </div>
        </td>

        <td slot="created_at" slot-scope="{item}" class="align-middle">
          {{translateTimestamp(item.created_at)}}
        </td>

        <td slot="checkbox" slot-scope="{item, index}">
          <CInputCheckbox
            :checked.sync="item.checked"
            @update:checked="updateCheckbox(item.checked, index, item)"
          ></CInputCheckbox>
        </td>

        <td slot="status" slot-scope="{item}" class="align-middle">
          <CBadge :color="getBadgeColor(item.status)">{{item.status}}</CBadge>
        </td>

        <td slot="ops" slot-scope="{item, index}" class="text-center align-middle">
          <el-dropdown trigger="click" @command="(command) => $emit(command, item.id, index, item)">
            <span class="el-dropdown-link">
              <i class="el-icon-more text-dark" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="viewBtn" icon="el-icon-document" command="view">{{ $t('button.view') }}</el-dropdown-item>
              <el-dropdown-item v-if="editBtn" icon="el-icon-edit-outline" command="edit">{{ $t('button.edit') }}</el-dropdown-item>
              <el-dropdown-item icon="el-icon-delete" command="delete">{{ $t('button.delete') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>

        <td slot="del-icon" slot-scope="{item}" class="text-center">
          <el-tooltip class="item" effect="dark" content="delete" placement="right">
            <el-button type="text" icon="el-icon-delete" @click="$emit('delete', item.id)"></el-button>
          </el-tooltip>
        </td>

        <!-- <td slot="occupancy" slot-scope="{item}" class="align-middle">
          {{ item.occupancy }}%
          <el-tag class="ml-4" v-if="item.occupancy > 90" type="danger">Congested</el-tag>
        </td> -->

        <td slot="more_parking" slot-scope="{item}" class="align-right">
          <el-dropdown trigger="click" @command="(command) => $emit(command, item)">
            <span class="el-dropdown-link">
              <i class="el-icon-more text-dark" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-video-camera" command="video">{{ $t('button.live_video') }}</el-dropdown-item>
              <el-dropdown-item icon="el-icon-video-play" command="video">{{ $t('button.playback') }}</el-dropdown-item>
              <el-dropdown-item icon="el-icon-location-information" command="place">{{ $t('button.place_overview') }}</el-dropdown-item>
              <el-dropdown-item icon="el-icon-warning-outline" command="staff">{{ $t('button.notification') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>

        <td slot="attrs" slot-scope="{item}">
          <CBadge class="mr-1" :color="['success','warning','primary'][index]" v-for="(attr, index) in item.attrs" :key="`attrs-index-${index}`">{{attr}}</CBadge>
        </td>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EdxTable',
  props: {
    items: { type: Array },
    fields: { type: Array },
    caption: { type: String, default: 'Data' },
    count: { type: Number, default: 0 },
    hover: { type: Boolean },
    striped: { type: Boolean },
    border: { type: Boolean },
    small: { type: Boolean },
    fixed: { type: Boolean },
    dark: { type: Boolean },
    clickableRows : { type: Boolean, default: false },
    headerSvg: {type: String},
    headerIcon: { type: String},
    addBtn: { type: Boolean, default: false },
    editBtn: { type: Boolean, default: false },
    viewBtn: { type: Boolean, default: true },
    importBtn: { type: Boolean, default: true },
    addBtnIcon: { type: String, default: 'cil-plus' },
    addBtnText: { type: String, default: 'Add' },
    checkable : {type:Boolean, default:false}, // click UI 
  },
  data(){
    return {
      c_items : [], // checkable 에서 사용하기위한 items 복사본
      is_all_checked : false,
      // operator : false,
      checked_items : [],
    }
  },
  created(){
    this.initCheckbox();
  },
  watch : {
    items : function(){ // page 변경 시 또는 delete 후 로직을 위해
      this.initCheckbox();
    }
  },
  computed : {
    ...mapState([
      'capability'
    ]),
  },
  methods: {
    translateTimestamp(ts){
      return new Date(ts).toLocaleString();
    },
    onClickCheckbox(){
      // 전체가 체크 되었을 경우에는 체크 해제
      if(this.isAllCheckboxChecked()){
        this.updateStateCurrentPageAllItems(false);
      }else{
        // 그 외는 전체 체크
        this.updateStateCurrentPageAllItems(true);
      }
    },
    deleteCheckedItems(){
      if(!this.deleteItemsValidate())
        return;

      this.$confirm(this.$t('cloud.title.confirm_remove', {count: this.checked_items.length}), this.$t('cloud.title.alert'), {
        confirmButtonText:'Delete',
        cancelButtonText:'Cancel',
        type:'warning'
      })
      .then(() => {
        this.$emit('delete-checked-items', this.checked_items); // checked_items 원소 제거
        this.initCheckbox();
      })
    },
    deleteItemsValidate(){
      // selected validate - #1
      if(this.checked_items.length === 0){ // 셀렉트된 아이템이 없을 경우
        this.$notify.info({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.no_selected_items'),
          offset: 30
        });
        return false;
      }
      // selected - validate - #2
      let my_account_check = this.checked_items.filter((item)=>{
        return item.id === this.capability.user_profile.id;
      })
      if(my_account_check.length > 0){
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.self_remove'),
          offset: 30
        });
        return false;
      }
      return true;
    },
    updateStateCurrentPageAllItems(checked){
      this.is_all_checked = checked;
      this.c_items.forEach((item, idx)=>{
        if(checked){
          this.$set(this.c_items[idx],'checked', true);
          this.$set(item,'_classes','table-info');
        }else{
          this.$set(this.c_items[idx],'checked', false);
          this.$set(item,'_classes','');
        }
      })
      this.checked_items = this.returnCheckedItems();
    },
    updateCheckbox(checked, index, item){
      if(checked){
        this.$set(this.c_items[index],'_classes','table-info');
      }  
      else{
        this.$set(this.c_items[index],'_classes','');
      }
      this.checked_items = this.returnCheckedItems();
      if(this.checked_items===null){ 
        this.is_all_checked = false;
      }else{        
        this.is_all_checked = true;
      }
    },
    returnCheckedItems(){
      let items = this.c_items.filter((item)=>{
        return item.checked===true;
      });

      if(items.length===0){ // 체크된 아이템이 하나도 없다면
        this.is_all_checked = false;
        // this.operator = false;
        return null; // 체크된 아이템이 하나도 없다면 초기화 후 null 반환
      }else{
        // this.operator = true;
        return items; // 체크된 아이템 반환
      }
    },
    initCheckbox(){
      // 초기화
      this.is_all_checked = false; // 체크박스 현 상태 초기화 (false)
      // this.operator = false; // operator 메뉴 open 여부 확인 초기화
      this.checked_items = []; // 체크된 아이템들의 정보 배열 초기화
      this.c_items = JSON.parse(JSON.stringify(this.items));
      this.c_items.forEach((item,idx)=>{
        this.$set(this.c_items[idx],'checked', false);
        this.$set(this.c_items[idx],'_classes','');
      })
    },
    isAllCheckboxChecked(){
      let uncheck_item = this.c_items.filter((item)=>{
        return item.checked === false;
      })
      if(uncheck_item.length === 0 ) // 언체크된 아이템이 하나도 없다면
        return true;
      else
        return false;
    },
    getBadgeColor (status) {
      return status === 'Active' ? 'success'
        : status === 'Disconnected' ? 'danger'
          : status === 'AuthFailed' ? 'warning': 'primary'
    },
    onImport: function(e) {
      this.$emit('import');
    },
    onImportExample : function(){
      this.$emit('import-example');
    },
    onAdd: function(e) {
      this.$emit('add');
    },
    onRowClick:function(e,index){
      // this.$set(this.items[index], '_classes', 'table-info');
      this.$emit('row-click', e, index);
    },
    getUserImageSrc(src){
      if(src==="") // not image
      { return "/img/default_user.png"; }
      else return src;
    },
    getTagColor(rate) {
      // if (rate < 70) return 'success';
      if (rate < 90) return 'success';
      return 'danger';
    }
  }
}
</script>
<style scoped>
.all_checkbox {
  position:absolute; 
  left:27px; 
  top:14px;
  z-index:2;
}
.multiBox {
  position:relative; 
  width:5%; 
  /* height:40px; */
  height:1px;
}
.checkbox_label{
  position:absolute;
  left:26px;
  top:17px;
  z-index:2;
}
.checkbox_icon_wrap {
  background-color:#0077FF; 
  width:15px; 
  height:15px; 
  /* border:1px solid black;  */
  border-radius:2px; 
  position:relative;
}
.checkbox_icon {
  position:absolute;
  left:2.5px;
  top:2.5px;
  color:white;
  font-weight:bold;
  font-size:0.6rem;
}
</style>